import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ILinkMenu } from './menu';
import { useCurrentPath } from '../utils/component-utils';

const TopLink: React.FC<{ menu: ILinkMenu }> = ({ menu }) => {
  const currentPath = useCurrentPath(menu.path);
  const isActive = useMemo(() => menu.path === currentPath, [currentPath, menu]);

  return (
    <li>
      <Link
        className={`block ${isActive ? 'text-blue-300' : 'text-white'}`}
        to={menu.base || menu.path}
      >
        {menu.text}
      </Link>
    </li>
  )
};

export default memo(TopLink);

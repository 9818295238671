import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import _uniq from 'lodash/uniq';
import { Navigate, Outlet } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import Appbar from '../components/appbar';
import { AppContext } from '../context';
import LoadingBar from 'react-top-loading-bar';
import IDataExtension, { IOptOut } from '../models/data-extension';
import { apiInstance } from '../utils/api-service';
import { ToastContainer } from 'react-toastify';
import ICampaign, { IConversation } from '../models/message';
import ITrackingURL, { IGlobalTrackingURL } from '../models/tracking-url';
import IConfigurationData from '../models/configuration-data';

try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.log(e);
}

const timeAgo = new TimeAgo('en-US');

const Home: React.FC = () => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [pageLoading, setPageLoading] = useState(false);
  const [senderPhones, setSenderPhones] = useState<string[]>([]);
  const [dataExtensions, setDataExtensions] = useState<IDataExtension[]>([]);
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [optoutList, setoptOutList] = useState<IOptOut[]>([]);
  const [trackingUrls] = useState<ITrackingURL[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [userToken, setUserToken] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [globalTrackingURLs, setGlobalTrackingURLs] = useState<IGlobalTrackingURL[]>([]);
  const [configurationData, setConfigurationData] = useState<IConfigurationData[]>([]);
  const loadingBar = useRef(null);
  const smsDisplayListConversation = useMemo(() => conversations.filter(item => item.myPhoneNumber?.replace('+', '') === currentPhoneNumber?.replace('+', '')), [currentPhoneNumber, conversations]);
  const shortenDomains = useMemo(() => {
    const domains = _uniq(configurationData.filter(item => item.type === 'domain').map(item => item.value));

    return [...domains, 'tinyurl.com'];
  }, [configurationData]);

  const fetchDataExtension = useCallback((userToken: string, onDone?: () => void) => {
    apiInstance(userToken)?.get('/data-extension').then(res => {
      setDataExtensions(res.data);

      if (typeof onDone === 'function') {
        onDone();
      }
    });
  }, []);

  const fetchCampaign = useCallback((userToken: string, onDone?: () => void) => {
    apiInstance(userToken)?.get('/campaign').then(res => {
      setCampaigns(res.data);

      if (typeof onDone === 'function') {
        onDone();
      }
    });
  }, []);

  const onStartLoading = useCallback(() => {
    if (loadingBar !== null && loadingBar.current !== null) {
      // @ts-ignore
      loadingBar.current.continuousStart();
      setPageLoading(true);
    };
  }, [loadingBar]);

  const onEndLoading = useCallback(() => {
    if (loadingBar !== null && loadingBar.current !== null) {
      // @ts-ignore
      loadingBar.current.complete();
      setPageLoading(false);
    };
  }, [loadingBar]);

  const getTrackingUrls = useCallback((startDate: Date, endDate: Date) => {
    return new Promise(resolve => {
      const key = `${startDate.getTime()}-${endDate.getTime()}`;

      const currentData = globalTrackingURLs.find(item => item.dateRange === key)?.data || [];

      if (currentData.length > 0) {
        resolve(currentData);
        return;
      }

      apiInstance(userToken, 'utils')?.get('/shorten', { params: { startDate: startDate.getTime(), endDate: endDate.getTime() } })
        .then(trackingUrlResponse => {
          const gotData = trackingUrlResponse.data;
          if (gotData.length > 0) {
            setGlobalTrackingURLs(existing => [...existing, { dateRange: key, data: trackingUrlResponse.data }]);
          }

          resolve(gotData || []);
        })
    })
  }, [globalTrackingURLs, userToken, setGlobalTrackingURLs]);

  const setTrackingUrls = useCallback((startDate: number, endDate: number, data: ITrackingURL[]) => {
    const key = `${startDate}-${endDate}`;

    setGlobalTrackingURLs(existings => [...existings, { dateRange: key, data }]);
  }, [setGlobalTrackingURLs]);

  const onModifyConfiguration = useCallback((data: IConfigurationData, type = 'modify') => {
    const onDone = () => {
      setPageLoading(false);
      apiInstance(userToken)?.get('/list-configuration').then(res => setConfigurationData(res.data));
    };

    setPageLoading(true);

    if (type === 'delete') {
      apiInstance(userToken)?.delete(`/list-configuration/${data.id}`).then(onDone);
    } else {
      apiInstance(userToken)?.post('/list-configuration/', data).then(onDone);
    }
  }, [userToken]);

  useEffect(() => {
    if (isAuthenticated && !userToken) {
      getIdTokenClaims().then(token => setUserToken(token?.__raw || ''));
    }
  }, [isAuthenticated, userToken, getIdTokenClaims]);

  useEffect(() => {
    if (userToken) {
      fetchDataExtension(userToken);
      apiInstance(userToken)?.get('/phone-number').then(res => setSenderPhones(res.data));
      apiInstance(userToken)?.get('/opt-out').then(res => setoptOutList(res.data));
      apiInstance(userToken)?.get('/list-configuration').then(res => setConfigurationData(res.data));
    }
  }, [userToken, fetchDataExtension]);

  if (isAuthenticated === false) {
    return <Navigate replace to='/login' />
  }

  return (
    <AppContext.Provider value={{
      pageLoading,
      onStartLoading,
      onEndLoading,
      dataExtensions,
      userToken,
      fetchDataExtension,
      campaigns,
      fetchCampaign,
      senderPhones,
      trackingUrls,
      formatTime: (time: number) => timeAgo.format(new Date(time)),
      conversations: smsDisplayListConversation,
      setConversations,
      optoutList,
      currentPhoneNumber,
      setCurrentPhoneNumber,
      // @ts-ignore
      getTrackingUrls,
      setTrackingUrls,
      shortenDomains,
      configurationData,
      onModifyConfiguration
    }}>
      <LoadingBar
        ref={loadingBar}
        color='#CC33CC'
      />
      <div className='flex flex-col bg-[#f4f4f4] min-h-screen'>
        <Appbar />
        <div className='flex-grow p-3 sm:p-5 flex flex-col relative'>
          <Outlet />
        </div>
        <div className='flex flex-row bg-[#222] text-white px-10 py-4'>
          Developed By TK Media
        </div>
      </div>
      <ToastContainer />
    </AppContext.Provider>
  )
}

export default memo(Home);

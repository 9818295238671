import React, { RefObject, memo, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOnClickOutside } from '../utils/component-utils';
import { menus } from './menu';
import TopLink from './top-link';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context';

const AppBar: React.FC = () => {
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const { user, logout } = useAuth0();
  const { senderPhones, currentPhoneNumber, setCurrentPhoneNumber } = useAppContext();
  const dropdownMenuWrapper: RefObject<HTMLDivElement> = useRef<
    HTMLDivElement
  >() as RefObject<HTMLDivElement>;
  const menuArea: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

  useOnClickOutside(dropdownMenuWrapper, () => setOpenUserMenu(false));

  useOnClickOutside(menuArea, () => setOpenNav(false));

  useEffect(() => {
    if (openNav === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [openNav]);

  useEffect(() => {
    if ((senderPhones?.length || 0) > 0 && !currentPhoneNumber) {
      setCurrentPhoneNumber(senderPhones[0]);
    }
    // eslint-disable-next-line
  }, [currentPhoneNumber, senderPhones]);

  return (
    <nav
      className="bg-[#222] border-gray-200">
      <div className="flex flex-wrap items-center justify-between mx-auto px-3 sm:px-4 p-4">
        <Link
          to="/"
          className="flex items-center"
        >
          <img
            src="https://onnesptea.com/wp-content/uploads/2023/03/Onnesptea-logo.png"
            className="h-8 mr-3" alt="Flowbite Logo"
          />
        </Link>
        <div className="flex items-center md:order-2">
          {
            (senderPhones?.length || 0) > 0 && (
              <select
                defaultValue={senderPhones[0]}
                onChange={(event) => setCurrentPhoneNumber(event.target.value)}
                className="bg-gray-50 cursor-pointer border w-[160px] sm:min-w-[180px] border-red-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 mr-2 sm:mr-4">
                {
                  currentPhoneNumber === '' && (
                    <option value=''>--Sender Number--</option>
                  )
                }
                {
                  senderPhones.map((phone: string, index: number) => (
                    <option key={index} value={phone}>{phone}</option>
                  ))
                }
              </select>
            )
          }

          <button onClick={() => setOpenUserMenu(!openUserMenu)} type="button" className="mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
            <span className="sr-only">Open user menu</span>
            <img
              className="w-8 h-8 rounded-full"
              src={user?.picture}
              alt="user avatar"
            />
          </button>
          <div ref={dropdownMenuWrapper} className={`z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 ? ${openUserMenu ? 'absolute top-10 right-1' : 'hidden'}`} id="user-dropdown">
            <div className="px-4 py-3">
              <span className="block text-sm text-gray-900 dark:text-white">
                {user?.nickname}
              </span>
              <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
                {user?.email}
              </span>
            </div>
            <ul className="py-2" aria-labelledby="user-menu-button">
              <li>
                <span
                  onClick={() => logout({
                    logoutParams: {
                      returnTo: window.location.origin
                    }
                  })}
                  className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Sign out
                </span>
              </li>
            </ul>
          </div>
          <button onClick={() => setOpenNav(!openNav)} data-collapse-toggle="navbar-user" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {
              menus.map(item => (
                <TopLink key={item.path} menu={item} />
              ))
            }
          </ul>
        </div>
      </div>
      <div className={`transition-all z-10 duration-500 absolute top-0 left-0 bg-white h-screen bg-opacity-60 ${openNav ? 'w-full opacity-100' : 'w-0 opacity-0'}`}>
        <div
          ref={menuArea}
          className='w-9/12 sm:w-4/12 bg-gray-200 opacity-100 h-screen overflow-hidden flex flex-col'>
          <div className='px-5 py-10 bg-jm-white flex flex-col space-y-3'>
          <ul className="flex flex-col space-y-3 font-medium p-4 md:p-0 mt-4 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {
              menus.map(item => (
                <TopLink key={`mobile-${item.path}`} menu={item} />
              ))
            }
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
};

export default memo(AppBar);
